<template>
    <div class="hero view__fullscreen" ref="el">
        <HeroCover />
        <div class="block">
            <div class="block__sizer">
                <div class="typography">
                    <h1 class="typography__title">MISS DIOR PARFUM</h1>
                    <p class="typography__p">
                        A floral and fruity bouquet with notes of jasmine, mandarin & amber woods lorem ipsum dolor sit
                        amet, consectetur adipiscing elit curabitur eget velit a elit finibus lacinia id at diam.
                    </p>
                </div>

                <div class="form">
                    <div class="form__fieldset" v-for="item in MissDiorParfum" :key="item.id">
                        <input class="form__radio" type="radio" :id="item.id" :value="item.variant" v-model="size" />
                        <label class="form__label" :for="item.id">{{ item.variant }}</label>
                    </div>
                </div>
                <CallToAction @action="handleOrder" label="" :value="current.price">
                    Order
                </CallToAction>
            </div>
            <div class="discover">
                <button class="button" @click="handleDiscover">
                    Discover more
                    <DownComponent />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import HeroCover from './Cover.vue'
import DownComponent from '@/components/Down.vue'

import { MissDiorParfum } from '@/api'

import Device from '@/pure/Device'
import gsap from '@gsap/shockingly'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'

export default {
    name: 'HeroComponent',
    components: { HeroCover, DownComponent },
    setup() {
        const el = ref(null);
        const size = ref("2.8 oz");
        const current = computed(() => {
            return MissDiorParfum.find(_ => _.variant === size.value);
        });
        const handleOrder = () => {
            window.open(current.value.url);
        }
        const handleDiscover = () => {
            gsap.to(window, {
                scrollTo: {
                    y: window.innerHeight,
                    autoKill: true
                },
                duration: 2,
                ease: 'power2'
            })
        }

        const breakPoint = 1024;

        onMounted(() => {
            const $el = el.value;
            const $block = $el.querySelector('.block');
            const $cover = $el.querySelector('.cover');
            const $cover1 = $el.querySelector('.cover__decoration:nth-child(1)');
            const $cover2 = $el.querySelector('.cover__decoration:nth-child(2)');
            const $picture = $el.querySelector('.cover__picture img');

            Device.matchMedia.add({
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
            },
                (context) => {
                    let { isDesktop, isMobile } = context.conditions;
                    if (isDesktop) {

                        const w = $cover.offsetWidth;
                        const x = window.innerWidth * 0.5 + w * 0.5;
                        const x2 = x - w - 16;

                        gsap.set($block, {
                            xPercent: -100,
                            opacity: 0,
                        });
                        gsap.set($cover, {
                            x: -($cover.offsetLeft + $cover.offsetWidth * 0.5) + window.innerWidth * 0.5,
                        })
                        gsap.set($cover1, {
                            x: -x
                        })
                        gsap.set($cover2, {
                            x: x
                        })

                        const animation = new gsap.timeline()
                            .to($cover1, {
                                x: -x2,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')
                            .to($cover2, {
                                x: x2,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')
                            .from($picture, {
                                y: `150.57915058%`,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')
                            .to($block, {
                                xPercent: 0,
                                opacity: 1,
                                ease: 'sineOut',
                                duration: 1,
                            }, 'b')
                            .to([$cover1, $cover2], {
                                x: 0,
                                ease: 'sineOut',
                                duration: 1
                            }, 'b')
                            .to($el.querySelector('.cover'), {
                                x: `-5.5%`,
                                ease: 'sineOut',
                                duration: 1,
                            }, 'b')

                        ScrollTrigger.create({
                            trigger: $el,
                            start: "top bottom",
                            end: "bottom top",
                            animation,
                            invalidateOnRefresh: true
                        })
                    } else if (isMobile) {
                        const w = $cover1.offsetWidth;
                        const x = window.innerHeight * 0.5 + w * 0.5;

                        const animation = new gsap.timeline()
                            .from($cover1, {
                                x: -x,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')
                            .from($cover2, {
                                x: x,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')
                            .from($picture, {
                                y: `150.57915058%`,
                                ease: 'sineOut',
                                duration: 1
                            }, 'a')

                        ScrollTrigger.create({
                            trigger: $el,
                            start: "top bottom",
                            end: "bottom top",
                            animation,
                            invalidateOnRefresh: true
                        })
                    }

                    return () => { };
                })
        })

        return {
            el,
            size,
            handleOrder,
            handleDiscover,
            MissDiorParfum,
            current
        }
    }
}
</script>

<style lang="scss" scoped>
.hero {
    padding-left: 36px;
    padding-right: 36px;
    overflow: hidden;
}

.cover {
    margin: auto;

    @include respond-to("md") {
        order: 2;
        transform: translateX(-5.5%);
    }
}

.block {
    margin: 94px auto 60px;

    @include respond-to("md") {
        display: flex;
        align-self: stretch;
    }

    &__sizer {
        @include respond-to("md-down") {
            padding: 0 10px;
            margin-top: 70px;
        }

        @include respond-to("md") {
            width: 504px;
            padding-right: 94px;
            margin: auto;
            padding-left: 16px;
        }
    }
}

.typography {
    &__title {
        letter-spacing: 0.04em;
    }

    &__p {
        max-width: 346px;
        margin-top: 4px;
    }

    @include respond-to("md") {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.call-to-action {
    width: 100%;
}

.discover {
    position: absolute;
    bottom: -35px;
    left: 16px;
    right: 0%;
    width: 504px;
    margin: auto;

    @include respond-to("md-down") {
        display: none;
    }
}

.button {
    padding-left: 0;
}
</style>
