<template>
    <svg fill="none" height="7" viewBox="0 0 12 7" width="12" xmlns="http://www.w3.org/2000/svg"><path d="m1 1.25952 4.84009 4.84009 4.96621-4.96618" stroke="#000" stroke-linecap="round" stroke-linejoin="round"/></svg>
</template>

<script>
export default {
    name: 'DownComponent'
}
</script>

<style scoped lang="scss">
</style>