export const slides = [
    {
        title: "Discovery\nof the garden",
        media: {
            default: require("@/assets/images/slider/discovery-of-the-garden.png"),
            "2x": require("@/assets/images/slider/discovery-of-the-garden@2x.png"),
        }
    },
    {
        title: "Extraction\nessences",
        media: {
            default: require("@/assets/images/slider/extraction-essence.png"),
            "2x": require("@/assets/images/slider/extraction-essence@2x.png"),
        }
    },
    {
        title: "The symbiosis\nlorem ipsum",
        media: {
            default: require("@/assets/images/slider/the-symbiosis.png"),
            "2x": require("@/assets/images/slider/the-symbiosis@2x.png"),
        }
    },
    {
        title: "FROM flower\nTO bottle",
        media: {
            default: require("@/assets/images/slider/from-flower-to-bottle.png"),
            "2x": require("@/assets/images/slider/from-flower-to-bottle@2x.png"),
        }
    },
    {
        title: "miss dior\nscent",
        media: {
            default: require("@/assets/images/slider/miss-dior-scent.png"),
            "2x": require("@/assets/images/slider/miss-dior-scent@2x.png"),
        }
    },
];
