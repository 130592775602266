<template>
    <div class="biancalana">
        <div class="view__fullscreen">
            <div class="block">
                <div class="wrapper">
                    <div class="typography">
                        <h2 class="typography__title typography__white">Carole Biancalana</h2>
                        <p class="typography__accent typography__white">Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit Mauris tristique</p>
                        <p class="typography__p typography__white">Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Curabitur eget velit a
                            elit finibus lacinia id at diam. Proin placerat dignissim risus ac finibus. Sed fermentum,
                            sem et
                            dignissim venenatis, felis massa volutpat eros.</p>
                        <CallToAction @action="handleAction" class="call-to-action__white">
                            Discover
                        </CallToAction>
                    </div>
                </div>
            </div>
            <div class="illustration">
                <div class="illustration__video">
                    <VideoComponent
                        :url="require('@/assets/videos/Dior Made With Love - The Flower of Creation.mp4')"
                        poster="https://dior-flowers-macys.almageste.dev/assets/poster.png"
                    />
                </div>
                <div class="illustration__image">
                    <img src="@/assets/images/about/biancalana-carole.png" />
                </div>
            </div>
        </div>
        <div class="marquee">
            <div class="marquee__inner" aria-hidden="true">
                <span>MADE WITH LOVE</span>
                <span>MADE WITH LOVE</span>
                <span>MADE WITH LOVE</span>
                <span>MADE WITH LOVE</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BiancalanaComponent',
    setup() {
        const handleAction = () => {
            window.open("https://www.dior.com/fr_fr")
        }
        return {
            handleAction
        }
    }
}
</script>

<style lang="scss" scoped>
.biancalana {
    background-color: var(--rose);
    color: var(--white);

    @include respond-to("md") {
        .view__fullscreen {
            min-height: 990px;
        }
    }

    @include respond-to("md-down") {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}

.view__fullscreen {
    padding: 16px 10px;
}

.block {
    display: flex;

    @include respond-to("md-down") {
        width: 100%;
    }

    @include respond-to("md") {
        flex-grow: 0.5;
        flex-shrink: 0.1;
        flex-basis: auto;
        margin-left: var(--gutter);
    }

    @include respond-to("lg") {
        flex: 1;
        // margin-left: 0;
    }
}

.wrapper {
    margin: auto;

    @include respond-to("md") {
        width: 100%;
        margin-bottom: -80px;
        max-width: 504px;
    }
}

.typography__accent {
    margin-top: 12px;
    margin-bottom: 16px;
}

.call-to-action {
    margin-top: 30px;
    width: 201px;
}

.marquee {
    font-family: var(--title-font);
    font-size: 206px;
    font-weight: 700;
    opacity: 0.2;
    position: relative;
    overflow: hidden;
    --offset: -1121px;
    --move-initial: 0px;
    --move-final: var(--offset);
    transform: translateY(-50%);
    margin-top: 0.15em;

    @include respond-to("md-down") {
        display: none;
    }

    &__inner {
        width: fit-content;
        display: flex;
        position: relative;
        transform: translate3d(var(--move-initial), 0, 0);
        will-change: transform;
        @media (prefers-reduced-motion: no-preference) {
            animation: marquee 5s linear infinite;
        }
    }

    span {
        white-space: nowrap;
        padding-left: .2em;
    }
}

.illustration {
    display: flex;
    justify-content: center;

    @include respond-to("md-down") {
        margin-top: 60px;
    }

    @include respond-to("md") {
        width: 100%;
    }

    @include respond-to("lg") {
        flex: 1;
        justify-content: flex-start;
        margin-right: var(--gutter);
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        background-color: var(--black);
    }

    &__video {
        width: xs-ratio(280px);
        height: xs-ratio(495px);
        border-radius: 20px;
        z-index: 1;
        position: relative;
        overflow: hidden;

        @include respond-to("md-down") {
            margin-top: xs-ratio(227px);
        }

        @include respond-to("md") {
            position: relative;
            bottom: -18.75vw;
            width: 27.9166666667vw;
            height: 49.375vw;
        }

        @include respond-to("lg") {
            bottom: -170px;
            width: 402px;
            height: 711px;
        }
    }

    &__image {
        width: xs-ratio(184px);
        height: xs-ratio(276px);
        border-radius: 20px;
        z-index: 2;
        margin-left: xs-ratio(-130px);
        overflow: hidden;

        @include respond-to("md") {
            width: 21.26vw;
            height: 32.5694444444vw;
            margin-left: -2.7777777778vw;
        }

        @include respond-to("lg") {
            width: 312px;
            height: 469px;
            margin-left: -40px;

        }
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(var(--move-initial), 0, 0);
    }

    100% {
        transform: translate3d(var(--move-final), 0, 0);
    }
}
</style>