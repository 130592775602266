<template>
    <div class="slider view__fullscreen">
        <h2 class="typography__title">NOTRE SAVOIR&#8209;FAIRE</h2>
        <div class="container">
            <InfiniteSlider :slides="slides"></InfiniteSlider>
        </div>
    </div>
</template>

<script>
import { slides } from '@/locales'
import InfiniteSlider from './InfiniteSlider.vue';

export default {
    name: 'SliderMobileComponent',
    components: { InfiniteSlider },
    setup() {
        return {
            slides
        }
    }
}
</script>

<style lang="scss" scoped>
.slider {
    margin-top: 75px;
    margin-bottom: 32px;
}
.typography__title {
    text-align: center;
    max-width: 5em;
}
.container {
    width: 100%;
    overflow: hidden;
    margin-top: 15px;
}
</style>
