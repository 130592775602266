import axios from "axios";

const _auth = {
    username: process.env.VUE_APP_PUBLIC_USERNAME,
    password: process.env.VUE_APP_PUBLIC_PWD
};

const catchError = function (error) {
    if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log('Error', error.message);
    }
    console.log(error.config);
}

export const defaultFilters = {
    // category: 6,
    // collection: 2,
    // capacity: 3
};

export const getFilters = async () => {
    const response = await axios
        .get(`${process.env.VUE_APP_API_URL}/api/filters`, {
            auth: _auth
        })
        .then(response => (response.data.data))
        .catch(catchError);
    return response;
}

export const getProducts = async (params = {}) => {
    const response = await axios
        .get(`${process.env.VUE_APP_API_URL}/api/products`, {
            auth: _auth,
            params
        })
        .then(response => (response.data.data))
        .catch(catchError);
    return response;
}

// This should be a getProducts() with the correct params..
export const MissDiorParfum = [
    {
        id: "1",
        variant: "1.1 oz",
        price: "$110.00",
        url: "https://www.dior.com/en_us/beauty/products/miss-dior-parfum-C099700899.html"
    },
    {
        id: "2",
        variant: "1.7 oz",
        price: "$140.00",
        url: "https://www.dior.com/en_us/beauty/products/miss-dior-parfum-C099700898.html"
    },
    {
        id: "3",
        variant: "2.8 oz",
        price: "$165.00",
        url: "https://www.dior.com/en_us/beauty/products/miss-dior-parfum-C099700897.html"
    }
];
