import { ref } from 'vue'

import gsap from '@gsap/shockingly'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'
import Draggable from '@gsap/shockingly/Draggable'
import InertiaPlugin from '@gsap/shockingly/InertiaPlugin'
import ScrollToPlugin from '@gsap/shockingly/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger, Draggable, InertiaPlugin, ScrollToPlugin)

const handleResize = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

ScrollTrigger.addEventListener('refresh', handleResize)
window.addEventListener('resize', handleResize);

const matchMedia = gsap.matchMedia();
const isMobile = ref(false);
const breakPoint = 1024;

matchMedia.add({
    isDesktop: `(min-width: ${breakPoint}px)`,
    isMobile: `(max-width: ${breakPoint - 1}px)`,
},
    (context) => {
        let { isDesktop } = context.conditions;
        isMobile.value = !isDesktop;
        
        return () => {};
    })

export default {
    matchMedia,
    isMobile
}
