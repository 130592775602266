<template>
    <div class="miss-dior">
        <img class="cover__decoration" src="@/assets/images/miss.svg" alt="MISS, half of MISS DIOR" />
        <img class="cover__decoration" src="@/assets/images/dior.svg" alt="DIOR, half of MISS DIOR" />
    </div>
</template>

<script>
export default {
    name: 'MissDior',
}
</script>

<style lang="scss" scoped>
.miss-dior {
    display: flex;
    gap: 1.75vw;
    @include respond-to("xs-down") {
        gap: 3vw;
    }

    img {
        height: 20.275vw;
        width: auto;

        @include respond-to("xs-down") {
            height: 40.75vw;
        }
    }
}
</style>
