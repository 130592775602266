<template>
    <div class="filters">
        <div class="filters__count">{{ `${count} article${count > 1 ? 's' : ''}` }}</div>
        <template v-if="!isMobile">
            <Transition name="fade">
                <button class="filters__clear" @click="clearFilters" v-if="filtered">Reset all filters</button>
            </Transition>
            <Transition name="fade">
                <span class="filters__separator" v-if="filtered">|</span>
            </Transition>
            <SelectComponent :label="data.categories.label" :options="data.categories.items" v-model="category"
                @update:model-value="(_) => handleValue(_, category)" />
            <SelectComponent :label="data.collections.label" :options="data.collections.items" v-model="collection"
                @update:model-value="(_) => handleValue(_, collection)" />
            <SelectComponent :label="data.capacities.label" :options="data.capacities.items" v-model="capacity"
                @update:model-value="(_) => handleValue(_, capacity)" />
        </template>
        <button
            class="filters__button"
            v-else
            @click="modal = true"
        >
            Filter {{ countFilter ? `(${countFilter})` : '' }}
        </button>
        <Teleport to="body" v-if="modal">
            <div class="view__modal modal">
                <div class="modal__header" @click="modal = false">
                    <h2 class="typography__subtitle">FILTER</h2>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20"><path fill="currentColor" d="m10.71 10 4.65-4.66a.495.495 0 1 0-.7-.7L10 9.29 5.34 4.64a.495.495 0 0 0-.7.7L9.29 10l-4.65 4.66a.48.48 0 0 0 0 .7.48.48 0 0 0 .7 0L10 10.71l4.66 4.65a.48.48 0 0 0 .7 0 .48.48 0 0 0 0-.7z"></path></svg>
                </div>
                <div class="modal__body">
                    <SelectComponent :label="data.categories.label" :options="data.categories.items" v-model="category"
                        @update:model-value="(_) => handleValue(_, category)" />
                    <SelectComponent :label="data.collections.label" :options="data.collections.items"
                        v-model="collection" @update:model-value="(_) => handleValue(_, collection)" />
                    <SelectComponent :label="data.capacities.label" :options="data.capacities.items" v-model="capacity"
                        @update:model-value="(_) => handleValue(_, capacity)" />
                </div>
                <div class="modal__footer">
                    <Transition name="fade">
                        <button class="filters__clear" @click="clearFilters" v-if="filtered">Reset all filters</button>
                    </Transition>
                    <CallToAction @action="modal = false" class="call-to-action__black">
                        DONE
                    </CallToAction>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import { computed, ref } from 'vue'

import Device from '@/pure/Device'

export default {
    name: 'FiltersComponent',
    props: {
        data: Object,
        count: Number
    },
    emits: ['filter'],
    setup(props, { emit }) {
        const category = ref("");
        const collection = ref("");
        const capacity = ref("");
        const modal = ref(false);

        const filters = () => {
            emit('filter', {
                category: category.value,
                collection: collection.value,
                capacity: capacity.value,
            })
        }
        const handleValue = (newValue, current) => {
            current = newValue;
            filters(current);
        }
        const clearFilters = () => {
            category.value = ""
            collection.value = ""
            capacity.value = ""

            emit('filter', {
                category: category.value,
                collection: collection.value,
                capacity: capacity.value,
            })
        }
        const countFilter = computed(() => {
            let count = 0;
            if (category.value) count++
            if (collection.value) count++
            if (capacity.value) count++
            return count;
        })
        const filtered = computed(() => {
            return category.value || collection.value || capacity.value
        })
        return {
            category,
            collection,
            capacity,
            handleValue,
            clearFilters,
            filtered,
            countFilter,
            isMobile: Device.isMobile,
            modal
        }
    }
}
</script>

<style lang="scss" scoped>
.filters {
    display: flex;
    padding: 0px 30px;
    font-size: 13px;
    align-items: center;

    &__count {
        margin-right: auto;
        color: var(--near-grey);
    }

    &__separator {
        color: var(--near-grey);
    }

    &__clear {
        padding: 10px 16px;
        cursor: pointer;
        user-select: none;
        color: var(--gray);
        position: relative;
        background-color: transparent;
        border: none;

        &:after {
            content: "";
            display: block;
            background-color: var(--gray);
            position: absolute;
            height: 1px;
            width: auto;
            bottom: 8px;
            left: 16px;
            right: 16px;
        }
    }

    &__button {
        text-decoration: underline;
        background-color: transparent;
        border: none;
    }
}

.select {
    font-family: inherit;
    font-size: inherit;

    @include respond-to("xs-down") {
        display: none;
    }
}

.typography__subtitle {
    font-family: var(--corps-font);
    font-size: 13px;
    color: inherit;
}

.call-to-action {
    width: 100%;
}
</style>
