<template>
  <div class="header">
    <router-link to="/">
      <img src="@/assets/images/LOGO_DIOR.svg" alt="LOGO DIOR">
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent'
}
</script>

<style scoped lang="scss">
.header {
  padding-top: env(safe-area-inset-top);
  margin: var(--header-gutter-y) var(--header-gutter-x) var(--header-gutter-y);

  @include respond-to("md-down") {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @include respond-to("md") {
    position: absolute;
    top: 0;
    left: env(safe-area-inset-left);
  }
}
</style>
