<template>
    <div class="infinite-slider" ref="wrapper">
        <div class="card" v-for="(slide, i) in slides" :key="i" ref="movieHelpers">
            <div class="size-helper">
                <img class="main" :src="slide.media['2x']">
                <CopyComponent :slide="slide" />
            </div>
        </div>
    </div>
</template>

<script>
import gsap from '@gsap/shockingly'
import Draggable from '@gsap/shockingly/Draggable'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'
import { ref, onMounted, onUnmounted } from 'vue'

import CopyComponent from './Copy.vue'

export default {
    components: { CopyComponent },
    props: {
        slides: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const wrapper = ref(null);
        const movieHelpers = ref(null);
        var proxy = document.createElement("div");
        var wrapProgress = gsap.utils.wrap(0, 1);
        var proxyProps = gsap.getProperty(proxy);
        var boxWidth = 0;
        var wrapWidth = 0;
        var snapBox = null;
        var animation = null;
        var overrideAnimation = null;
        var updateProgress = null;
        var draggable = null;

        const handleResize = () => {
            boxWidth = movieHelpers.value[0].querySelector(".size-helper").offsetWidth + 15;

            wrapWidth = props.slides.length * boxWidth;
            
            for (var i = 0; i < movieHelpers.value.length; i++) {
                let box = movieHelpers.value[i];
                gsap.set(box, { x: i * boxWidth, left: -boxWidth * 2 });
                gsap.set(box.querySelector('.size-helper'), { width: boxWidth - 15 });
            }

            snapBox = gsap.utils.snap(boxWidth);

            updateProgress = () => {
                animation.progress(wrapProgress(proxyProps("x") / wrapWidth));
            }
            animation = gsap.to(movieHelpers.value, {
                duration: 1,
                x: "+=" + wrapWidth,
                ease: "linear",
                paused: true,
                repeat: -1,
                modifiers: {
                    x: function(x) {
                        x = parseFloat(x) % wrapWidth;
                        return x + "px";
                    }
                }
            }).progress(1 / props.slides.length);

            updateProgress();
            if (draggable) {
                draggable[0].kill();
            }
            draggable = Draggable.create(proxy, {
                type: "x",
                trigger: wrapper.value,
                throwProps: true,
                onPressInit: () => {
                    if (overrideAnimation) {
                        overrideAnimation.kill();
                    }       
                },
                onDrag: updateProgress,
                onThrowUpdate: updateProgress,
                snap: {
                    x: snapBox
                }
            });
        }
        onMounted(() => {
            handleResize();
            ScrollTrigger.addEventListener('refreshInit', handleResize);
            window.addEventListener('resize', handleResize);
        });
        onUnmounted(() => {
            ScrollTrigger.removeEventListener('refreshInit', handleResize);
            window.removeEventListener('resize', handleResize);
        });
        const handleAction = () => {}
        return {
            wrapper,
            movieHelpers,
            handleAction
        }
    }
}
</script>

<style lang="scss" scoped>
.infinite-slider {
    display: flex;
    overflow: visible;
    width: 340px;
    margin-left: 50%;
    position: relative;
    height: 665px;
    transform: translateX(-50%);
}
.card {
    position: absolute;
    width: 340px;
    margin-right: 15px;
    height: 100%;
    overflow: hidden;
    border-radius: 20px;
    background-color: var(--white);
}
.size-helper {
    height: 100%;
    width: 340px;
}
img {
    display: block;
    height: 340px;
    width: 340px;
    border-radius: 10px;
    margin-bottom: 24px;
}
</style>
