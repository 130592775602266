<template>
    <div class="cover">
        <div class="cover__sizer">
            <img class="cover__decoration" src="@/assets/images/miss.svg" alt="MISS, half of MISS DIOR" />
            <img class="cover__decoration" src="@/assets/images/dior.svg" alt="DIOR, half of MISS DIOR" />
            <picture class="cover__picture">
                <source srcset="@/assets/images/flacon.png, @/assets/images/flacon@2x.png 2x" />
                <img src="@/assets/images/flacon.png" alt="Perfum MISS DIOR" />
            </picture>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroCover'
}
</script>

<style lang="scss" scoped>
.cover {
    position: relative;
    max-width: 100%;
    will-change: transform;

    @include respond-to("md-down") {
        width: auto;
        align-self: normal;
        max-width: 585px;
    }

    &__sizer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 11.25px;
        position: relative;

        @include respond-to("md") {
            width: auto;
            max-height: calc(100vh - 34px);
            height: lg-ratio(816px);
            position: relative;
            aspect-ratio: 585 / 816;
        }
    }

    &__decoration {
        display: block;

        @include respond-to("md-down") {
            width: 100%;
        }
    }

    &__picture {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-43.625%) translateY(-50%);
        width: 75.7264957265%;

        @include respond-to("md-down") {
            transform: translateX(-50%) translateY(-50%);
            width: #{258/393*100%};

        }
    }

    img {
        width: 100%;
        will-change: transform;
    }
}
</style>
