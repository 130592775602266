<template>
    <div class="range" ref="el">
        <h2>DISCOVER THE RANGE</h2>
        <FiltersComponent v-if="products && filters" :count="products.length" :data="filters" @filter="handleFilter" />

        <Transition :css="false" @enter="handleEnter" @leave="handleLeave" mode="out-in">
            <div class="products" v-if="!loading">
                <TransitionGroup :css="false" @enter="handleGroupEnter">
                    <ProductCard v-for="(product, i) in products" :key="product.url" :product="product"
                        :data-index="i % 8" />
                </TransitionGroup>
                <ProductCard v-for="product in Math.max(0, 8 - products.length)" :key="product"
                    class="product__placeholder" />
            </div>
            <div class="products" v-else>
                <ProductCard v-for="product in 8" :key="product" />
            </div>
        </Transition>
        <div class="range__footer" v-if="nextPageAvailable">
            <CallToAction @action="handleAction" :class="{ 'call-to-action__disabled': activity }">
                Load more products
            </CallToAction>
        </div>
    </div>
</template>

<script>
import { ref, computed, reactive, onMounted, watch, nextTick } from 'vue'
import { getFilters, getProducts, defaultFilters } from '@/api/index.js'
import gsap from '@gsap/shockingly'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'

import FiltersComponent from './Filters.vue'
import ProductCard from './ProductCard.vue'
import Pagination from '@/pure/Pagination'

export default {
    name: 'RangeComponent',
    components: { FiltersComponent, ProductCard },
    setup() {
        const el = ref(null);
        //  TODO START Composable
        const loading = ref(true);
        const activity = ref(true);
        const filters = ref(null);
        const products = ref(null);
        let currentParams = {};
        const pagination = reactive(new Pagination({
            size: 8
        }));

        getFilters().then((a) => {
            filters.value = a;
        });

        const fetchProducts = async (p) => {
            return await getProducts(p)
        }
        const handleFilter = (params) => {
            loading.value = true;
            activity.value = true;
            currentParams = params;
            pagination.reset();
            fetchProducts(params).then((a) => {
                products.value = a.items;
                pagination.totalItems = a.totalItems;
                pagination.totalPages = a.totalPages;
                loading.value = false;
                activity.value = false;
            });
        }

        const nextPageAvailable = computed(() => {
            return pagination.nextPageAvailable();
        })

        const handleAction = () => {
            activity.value = true;
            const page = pagination.nextPage();
            fetchProducts({
                ...currentParams,
                page
            }).then(a => {
                products.value = [...products.value, ...a.items];
            })
            activity.value = false;
        }

        handleFilter(defaultFilters);
        //  END Composable

        const handleEnter = (el, done) => {
            const tl = new gsap.timeline({ onComplete: done });
            tl.from(el.querySelectorAll('.product'), {
                alpha: 0,
                stagger: 0.1
            });
        }
        const handleGroupEnter = (el, done) => {
            gsap.from(el, {
                opacity: 0,
                delay: el.dataset.index * 0.1,
                onComplete: done
            });
        }
        const handleLeave = (el, done) => {
            const tl = new gsap.timeline({ onComplete: done });
            tl.to(el, {
                alpha: 0
            });
        }

        const pristine = computed(() => {
            return filters.value && products.value;
        })

        onMounted(() => {
            watch(pristine, (value) => {
                if (value) {
                    nextTick(() => {
                        gsap.set(el.value.querySelectorAll("h2, .filters__count, .select"), { autoAlpha: 0 }),
                        ScrollTrigger.batch(el.value.querySelectorAll("h2, .filters__count, .select"), {
                            onEnter: batch => gsap.to(batch, { duration: 1, autoAlpha: 1, stagger: 0.1 }),
                        });
                    })
                }
            }, { once: true })
    })

        return {
            el,
            loading,
            activity,
            filters,
            products,
            handleFilter,
            handleAction,
            nextPageAvailable,
            handleEnter,
            handleLeave,
            handleGroupEnter,
        }
    }
}
</script>

<style lang="scss" scoped>
h2 {
    font-family: var(--title-font);
    font-size: 130px;
    font-weight: 700;
    line-height: 121px;
    text-align: center;
    color: var(--rose);
    max-width: 441px;
    margin: auto;
    padding-top: 120px;
    padding-bottom: 33px;
    opacity: 0;

    @include respond-to("xs-down") {
        font-size: 70px;
        line-height: 61px;
        max-width: 240px;
        padding-top: 50px;
        padding-bottom: 20px;
    }

}

.products {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px 10px;
    grid-auto-rows: minmax(100px, auto);
    padding: 30px;

    @include respond-to("md-down") {
        grid-template-columns: repeat(3, 1fr);
    }

    @include respond-to("xs-down") {
        grid-template-columns: repeat(2, 1fr);
        padding: 30px 6px;
        grid-gap: 30px 5px;
    }

    @include respond-to("xl") {
        grid-template-columns: repeat(6, 1fr);
    }
}

.range__footer {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
