<template>
    <router-view />
</template>

<style lang="scss">
@import '@/styles/index.scss';

html {
    background-color: var(--snow);
}
</style>
