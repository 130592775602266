<template>
    <div :class="['reveal', {
        revealed,
        pristine
    }]" @click="handleClick">
        <div class="decoration">
            <div class="row">
                <MissDior />
                <MissDior />
                <MissDior />
            </div>
            <div class="row row-1">
                <MissDior />
                <MissDior />
                <MissDior />
            </div>
            <div class="row row-2">
                <MissDior />
                <MissDior />
                <MissDior />
            </div>
            <div class="row row-3">
                <MissDior />
                <MissDior />
                <MissDior />
            </div>
            <div class="row">
                <MissDior />
                <MissDior />
                <MissDior />
            </div>
        </div>
        <div class="cover">
            <picture class="cover__picture">
                <source srcset="@/assets/images/flacon.png, @/assets/images/flacon@2x.png 2x" />
                <img src="@/assets/images/flacon.png" alt="Perfum MISS DIOR" />
            </picture>
            <div class="composition">
                <div class="flower flower-1 flower__layout-1"></div>
                <div class="flower flower-2 flower__layout-4"></div>
                <div class="flower flower-3 flower__layout-4"></div>
                <div class="flower flower-4 flower__layout-4"></div>
                <div class="flower flower-5 flower__layout-1"></div>
                <div class="flower flower-6 flower__layout-a"></div>
                <div class="flower flower-7 flower__layout-2"></div>
                <div class="flower flower-8 flower__layout-b"></div>
                <div class="flower flower-9 flower__layout-3"></div>
                <div class="flower flower-10 flower__layout-3"></div>
                <div class="flower flower-11 flower__layout-4"></div>
                <div class="flower flower-12 flower__layout-c"></div>
                <div class="flower flower-13 flower__layout-1"></div>
                <div class="flower flower-14 flower__layout-4"></div>
                <div class="reveal-button"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import MissDior from '@/components/MissDior.vue'
export default {
    name: 'RevealComponent',
    components: { MissDior },
    setup() {
        const revealed = ref(false);
        const pristine = ref(true);
        const handleClick = () => {
            revealed.value = !revealed.value
        }
        return {
            revealed,
            pristine,
            handleClick
        }
    }
}
</script>

<style lang="scss" scoped>
.reveal {
    overflow: hidden;
    position: relative;
    height: 55.694vw;
    max-height: calc(var(--vh, 1vh)* 100);

    @include respond-to("xs-down") {
        height: 199.5024875622vw;
    }
}

.decoration {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: -1vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-to("xs-down") {
        margin-top: 1.5vw;
    }
}

.row {
    display: flex;
    gap: 2vw;
    margin-left: -145vw;
    margin-top: -1px;

    @include respond-to("xs") {
        margin-left: -11.75vw;

        &-2 {
            margin-left: -60.5vw;
        }

        &-3 {
            margin-left: -6.5vw;
        }
    }

    @include respond-to("xs-down") {
        &:nth-child(odd) {
            margin-left: 5vw;
        }

        &-3 {
            margin-left: 113vw;
        }

        .miss-dior {
            &:nth-child(3) {
                display: none;
            }
        }
    }
}

.composition {
    width: lg-ratio(617px);
    height: lg-ratio(646px);

    @include respond-to("xs-down") {
        width: lg-ratio(617px, $ratio);
        height: lg-ratio(646px, $ratio);
    }

    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    z-index: 1;
}

.flower {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    will-change: transform;
    @media (prefers-reduced-motion: no-preference) {
        transition: transform 1500ms ease-out;
    }
    transform: translate3d(var(--x), var(--y), 0) rotate(var(--rotation));
    --rotation: 0deg;

    &__layout-1 {
        background-image: url('@/assets/images/reveal/01.png');
    }

    &__layout-2 {
        background-image: url('@/assets/images/reveal/02.png');
    }

    &__layout-3 {
        background-image: url('@/assets/images/reveal/03.png');
    }

    &__layout-4 {
        background-image: url('@/assets/images/reveal/04.png');
    }

    &__layout-a {
        background-image: url('@/assets/images/reveal/image2.png');
    }

    &__layout-b {
        background-image: url('@/assets/images/reveal/image1.png');
    }

    &__layout-c {
        background-image: url('@/assets/images/reveal/image.png');
    }
}

.flower-1 {
    @include floral-ratio(247px, 252px, 211px, 737px);
    @include floral-ratio-revealed(-235px, 1409px, 34px, 408px);
}

.flower-2 {
    @include floral-ratio(203px, 203px, 418px, 603px);
    @include floral-ratio-revealed(812px, 233px, 823px, 6px);
}

.flower-3 {
    @include floral-ratio(267px, 267px, 436px, 413px);
    @include floral-ratio-revealed(782px, -218px, 588px, -165px);
}

.flower-4 {
    @include floral-ratio(203px, 203px, 289px, 437px);
    @include floral-ratio-revealed(-215px, -44px, 449px, -129px);
}

.flower-5 {
    @include floral-ratio(247px, 252px, 297.23px, 464.53px);
    --rotation: 41.69deg;
    @include floral-ratio-revealed(214.23px, -274.47px, 218.55px, -156.1px);
}

.flower-6 {
    @include floral-ratio(82.32px, 124.53px, 492.76px, 474.98px);
    --rotation: 65.81deg;
    @include floral-ratio-revealed(808.76px, -136.02px, 667.39px, -96.54px);
}

.flower-7 {
    @include floral-ratio(236px, 229px, 150px, 528px);
    @include floral-ratio-revealed(-245px, 473px, -66.53px, -142px);
}

.flower-8 {
    @include floral-ratio(202px, 197px, 152.87px, 564.74px);
    --rotation: -157.79deg;
    @include floral-ratio-revealed(-206.13px, 680.74, -64.84px, -120.39px);

    .pristine & {
        --y: #{top-lg-ratio(152.87px + 10px)};
        --x: #{left-lg-ratio(564.74px + 100px)};
    }
}

.flower-9 {
    @include floral-ratio(216px, 206px, 521px, 589px);
    @include floral-ratio-revealed(809px, 476px, 815px, 163px);
}

.flower-10 {
    @include floral-ratio(271.87px, 259.29px, 258.06px, 656.51px);
    --rotation: 90.34deg;
    @include floral-ratio-revealed(413.06px, 1435.51px, 245.15px, 406.77px);
}

.flower-11 {
    @include floral-ratio(203px, 203px, 388px, 826px);
    @include floral-ratio-revealed(811px, 1144px, 568px, 419px);
}

.flower-12 {
    @include floral-ratio(98.07px, 82.98px, 433.95px, 751.57px);
    --rotation: -127.35deg;
    @include floral-ratio-revealed(826.66px, 1165.7px, 578.33px, 403.06px);

    .pristine & {
        --y: #{top-lg-ratio(433.95px + 70px)};
        --x: #{left-lg-ratio(751.57px + 30px)};
    }
}

.flower-13 {
    @include floral-ratio(194.49px, 198.42px, 118.42px, 674.87px);
    --rotation: -125.17deg;
    @include floral-ratio-revealed(-223.58px, 823.87px, -126.99px, 196.45px);
}

.flower-14 {
    @include floral-ratio(226.48px, 226.48px, 481.64px, 752.64px);
    --rotation: -10.44deg;
    @include floral-ratio-revealed(809.64px, 806.64px, 814.97px, 324.97px);
}

.cover {
    position: absolute;
    left: 50%;
    top: 50%;

    &__picture {
        width: lg-ratio(515px);
        height: lg-ratio(518px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%) scale(0.86);
        @media (prefers-reduced-motion: no-preference) {
            transition: transform 1500ms ease-out;
        }

        .revealed & {
            transform: translateX(-50%) translateY(-50%) scale(1);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include respond-to("xs-down") {
            width: xs-ratio(303px);
            height: xs-ratio(305px);
        }
    }
}

.reveal-button {
    width: lg-ratio(100px);
    height: lg-ratio(100px);
    top: top-lg-ratio(499px);
    left: left-lg-ratio(464px);
    background-image: url('@/assets/images/reveal/reveal.svg');
    position: absolute;
    background-position: 50% 50%;
    background-size: cover;
    cursor: pointer;
    @media (prefers-reduced-motion: no-preference) {
        transition: opacity 1500ms ease-out;
    }

    .revealed & {
        opacity: 0;
        pointer-events: none;
    }

    @include respond-to("xs-down") {
        width: 24.8756218905vw;
        height: 24.8756218905vw;
        left: 0;
        right: 0;
        top: 77.25vw;
        margin: auto;
    }
}
</style>
