<template>
    <div class="birth" ref="el">
        <h2 class="typography__title">BORN<br />IN GRASSE<br />1947</h2>
        <div class="decoration">
            <div class="tryptic">
                <div class="tryptic__illustration" data-scale="0.574">
                    <img src="@/assets/images/birth/1.png" alt="rose" />
                </div>
                <div class="tryptic__illustration" data-scale="0.51">
                    <img src="@/assets/images/birth/2.png" alt="Carole humming flowers" />
                </div>
                <div class="tryptic__illustration" data-scale="0.39">
                    <img src="@/assets/images/birth/3.png" alt="Dior perfume" />
                </div>
            </div>
            <div class="tryptic">
                <div class="tryptic__illustration" data-scale="0.24">
                    <img src="@/assets/images/birth/6.png" alt="people with basket picking flowers" />
                </div>
                <div class="tryptic__illustration" data-scale="0.26">
                    <img src="@/assets/images/birth/5.png" alt="people picking flowers in a field" />
                </div>
                <div class="tryptic__illustration" data-scale="0.48">
                    <img src="@/assets/images/birth/4.png" alt="flowers picking" />
                </div>
            </div>
            <div class="cover" data-scale="0.5575">
                <img src="@/assets/images/about/video.png" alt="handpicking flowers" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, } from 'vue'

import Device from '@/pure/Device'
import gsap from '@gsap/shockingly'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'
export default {
    name: 'BirthComponent',
    setup() {
        const el = ref(null)
        onMounted(() => {
            const $els = el.value.querySelectorAll(".tryptic__illustration, .cover");
            [].forEach.call($els, (el) => {
                gsap.set($els, {
                    scale: el.dataset.scale,
                });
            })

            ScrollTrigger.batch($els, {
                onEnter: batch => {
                    gsap.to(batch, { duration: 1, scale: 1, autoAlpha: 1 })

                },
            });

            const breakPoint = 1024;
            Device.matchMedia.add({
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
            },
                (context) => {
                    let { isDesktop } = context.conditions;
                    if (isDesktop) {
                        const animation2 = new gsap.timeline()
                            .to(el.value.querySelectorAll('.tryptic__illustration'), {
                                y(y, i) {
                                    return gsap.getProperty(i, "marginTop") * -1
                                },
                                ease: 'linear'
                            }, 'a')
                            .fromTo(el.value.querySelectorAll('.cover'), {
                                y: 150,
                            }, {
                                y: -150,
                                ease: 'linear'
                            }, 'a')


                        ScrollTrigger.create({
                            trigger: el.value.querySelector('.decoration'),
                            start: "top bottom",
                            end: "bottom top",
                            scrub: true,
                            animation: animation2,
                            invalidateOnRefresh: true
                        })
                    }

                    return () => { };
                })
        })

        return {
            el
        }
    }
}
</script>

<style lang="scss" scoped>
.birth {
    padding: 60px 0 0;
    overflow: hidden;

    @include respond-to("md") {
        padding: 95px 0;
    }
}

.typography__title {
    text-align: center;
    margin: auto;
    letter-spacing: 0.04em;

    @include respond-to("xs") {
        font-size: 130px;
        line-height: 118px;
    }
}

.decoration {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;

    @include respond-to("md-down") {
        flex-direction: column;
    }
}

img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
}

.cover {
    min-width: 400px;
    width: 400px;
    height: 512px;
    border-radius: 20px;
    overflow: hidden;

    @include respond-to("md") {
        order: 1;
    }

    @include respond-to("xs-down") {
        min-width: 291px;
        width: 291px;
        height: 374px;
    }
}

.tryptic {
    display: flex;
    gap: 20px;
    align-items: center;

    @include respond-to("md-down") {
        flex-direction: column;
    }

    &__illustration {
        border-radius: 20px;
        overflow: hidden;

        @include respond-to("md") {
            width: 188px;
            height: 240px;

            &:nth-child(1) {
                margin-top: 63px;
            }

            &:nth-child(2) {
                margin-top: -215px;
            }

            &:nth-child(3) {
                margin-top: -70px;
            }
        }
    }

    &:nth-child(1) {
        @include respond-to("md-down") {
            flex-direction: column-reverse;

            .tryptic__illustration {
                &:nth-child(1) {
                    width: xs-ratio(236px);
                    height: xs-ratio(302px);
                    margin-top: xs-ratio(-40px);
                    margin-left: xs-ratio(50px);
                    z-index: -1;
                }

                &:nth-child(2) {
                    width: xs-ratio(136px);
                    height: xs-ratio(175px);
                    margin-right: xs-ratio(240px);
                    margin-top: xs-ratio(-76px);
                }

                &:nth-child(3) {
                    width: xs-ratio(178px);
                    height: xs-ratio(228px);
                    margin-left: xs-ratio(151px);
                }
            }
        }

    }

    &:nth-child(2) {
        @include respond-to("md") {
            flex-direction: row-reverse;
            order: 2;
        }

        @include respond-to("md-down") {
            .tryptic__illustration {
                &:nth-child(1) {
                    display: none;
                }

                &:nth-child(2) {
                    width: xs-ratio(178px);
                    height: xs-ratio(228px);
                    margin-right: xs-ratio(198px);
                }

                &:nth-child(3) {
                    width: xs-ratio(142px);
                    height: xs-ratio(182px);
                    margin-left: xs-ratio(162px);
                    margin-top: xs-ratio(-55px);
                }
            }
        }
    }
}
</style>
