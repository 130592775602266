<template>
    <div class="select">
        <label class="select__label">
            {{ currentLabel }}
            <svg fill="none" height="6" viewBox="0 0 11 6" width="11" xmlns="http://www.w3.org/2000/svg"><path d="m10 1-4.5 4-4.5-4" stroke="#808080" stroke-linecap="round"/></svg>
            <select class="select__input" v-model="value">
                <option value="">{{ !!value ? 'None' : label }}</option>
                <option v-for="option in options" :key="option.id" :value="option.id">{{ option.label }}</option>
            </select>
        </label>
    </div>
</template>

<script>
import { computed } from 'vue'

export default {
    name: 'SelectComponent',
    props: ['modelValue', 'options', 'label'],
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const value = computed({
            get() {
                return props.modelValue
            },
            set(value) {
                emit('update:modelValue', value)
            }
        })
        const currentLabel = computed(() => {
            return value.value ? props.options.find(_ => _.id === value.value).label : props.label
        })
        return {
            value,
            currentLabel
        }
    }
}
</script>

<style scoped lang="scss">
.select {
    display: block;
    position: relative;
    padding: 10px 16px;
    color: var(--near-grey);
    font-size: 13px;
    letter-spacing: 0.06em;
    cursor: pointer;
    
    &__label {
        display: flex;
        gap: 16px;
        align-items: center;
        cursor: pointer;
    }
    &__input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
    }
}
</style>