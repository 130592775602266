<template>
    <div class="typography">
        <h3 class="typography__subtitle">
            <Transition :css="false" @enter="yEnter" @leave="yLeave">
                <div class="helper" :key="idx">
                    <pre>{{ slide.title }}</pre>
                </div>
            </Transition>
        </h3>
        <p class="typography__accent">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p class="typography__p">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget
            velit a
            elit finibus lacinia id at diam. Proin placerat dignissim risus ac finibus. Sed fermentum, sem
            et
            dignissim venenatis, felis massa volutpat eros.</p>
        <p class="typography__p typography__p-mobile">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur eget velit a elit
            finibus lacinia id at diam.</p>
        <CallToAction @action="handleAction">
            <svg fill="none" height="22" viewBox="0 0 16 22" width="16" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="m8 0c-2.8483 0-5.17647 2.29778-5.17647 5.13333v.97778h-2.179567c-.371517 0-.643963.29333-.643963.63556v12.49113c0 1.5155 1.26316 2.7622 2.79876 2.7622h10.42724c1.5356 0 2.7988-1.2467 2.7988-2.7622v-12.49113c0-.36667-.2972-.63556-.644-.63556h-2.1548v-.97778c-.0248-2.83555-2.3529-5.13333-5.226-5.13333zm0 1.29556c2.1548 0 3.8638 1.68666 3.8638 3.83777v.97778h-7.72758v-.97778c0-2.12666 1.70898-3.83777 3.88855-3.83777zm-6.68731 6.11111h1.51084v1.83333c0 .36667.29721.66.64396.66.37152 0 .64396-.29333.64396-.66v-1.83333h7.75235v1.83333c0 .36667.2972.66.6439.66.3716 0 .644-.29333.644-.66v-1.83333h1.5108v11.83113c0 .8311-.6439 1.4666-1.486 1.4666h-10.37774c-.8421 0-1.48607-.6355-1.48607-1.4666z"
                    fill="#fff" />
            </svg> Order
        </CallToAction>
    </div>
</template>

<script>
import { useAnimations } from '@/composables/useAnimations'
export default {
    name: 'SliderComponent',
    props: ['slide', 'idx'],
    setup() {
        const handleAction = () => {
            window.open("https://www.dior.com/en_us/beauty/products/miss-dior-parfum-C099700897.html")
        }
        const { yEnter, yLeave } = useAnimations();

        return {
            handleAction,
            yEnter,
            yLeave
        }
    }
}
</script>

<style lang="scss" scoped>
.typography {
    @include respond-to("md-down") {
        padding: 0 24px;
    }
    &__subtitle {
        overflow: hidden;
        padding-top: 0.2em;
        position: relative;
    }

    &__accent {
        margin-top: 12px;
        margin-bottom: 16px;
        @include respond-to("md-down") {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    &__p {
        @include respond-to("md-down") {
            display: none;
        }
        &-mobile {
            display: none;
            @include respond-to("md-down") {
                display: block;
            }
        }
    }
}

.call-to-action {
    margin-top: 30px;
    width: 152px;
    @include respond-to("md-down") {
        margin-top: 20px;
        width: 100%;
    }
}
</style>
