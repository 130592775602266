import gsap from '@gsap/shockingly'

export function useAnimations() {
    const duration = 1
    const ease = "power2.inOut"
    const imageEnter = (el, done) => {
        const tl = gsap.timeline({
            onComplete: done
        })
        tl.from(el, { duration, ease, yPercent: 100 }, 'a')
        tl.from(el.querySelector("img"), { duration, ease, yPercent: -62.5 }, 'a')
    }
    const imageLeave = (el, done) => {
        const tl = gsap.timeline({
            onComplete: done
        })
        tl.to(el, { duration, ease, yPercent: -100 }, 'a')
        tl.to(el.querySelector("img"), { duration, ease, yPercent: 62.5 }, 'a')
    }

    const yEnter = (el, done) => {
        const tl = gsap.timeline({
            onComplete: done
        })
        tl.from(el, { duration: .6, ease: 'sine.inOut', yPercent: 100 }, 'a')
    }
    const yLeave = (el, done) => {
        const tl = gsap.timeline({
            onComplete: done
        })
        gsap.set(el, { position: 'absolute' })
        tl.to(el, { duration: .6, ease: 'sine.inOut', yPercent: -100 }, 'a')
    }
    return {
        imageEnter,
        imageLeave,
        yEnter,
        yLeave
    }
}