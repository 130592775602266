<template>
    <div :class="[
        'video-holder',
        {
            canplay,
            unplayed
        }
    ]">
        <video controls :poster="poster" ref="video">
            <source :src="url" type="video/mp4" />

            Download the
            <a :href="url">MP4</a>
            video.
        </video>
        <img class="play-button" src="@/assets/images/about/play.svg" alt="Play button">
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    name: 'VideoComponent',
    props: ['url', 'poster'],
    setup() {
        const video = ref("");
        const canplay = ref(false);
        const unplayed = ref(true);

        onMounted(() => {
            video.value.addEventListener("canplay", () => {
                canplay.value = true;
            });
            video.value.addEventListener("playing", () => {
                unplayed.value = false;
            });
            video.value.addEventListener("pause", () => {
                unplayed.value = true;
            });
            video.value.addEventListener("ended", () => {
                unplayed.value = true;
            });
        });

        return {
            video,
            canplay,
            unplayed
        }
    }
}
</script>

<style scoped lang="scss">
.video-holder {
    width: 100%;
    height: 100%;
}

video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    cursor: pointer;


    &::-webkit-media-controls-panel {
        .unplayed & {
            display: none !important;
            opacity: 1 !important;
        }
    }
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-top: -12px;
    opacity: 0;
    transition: opacity 1s linear;
    pointer-events: none;

    .canplay.unplayed & {
        opacity: 1;
    }
}
</style>