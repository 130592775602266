<template>
    <div class="call-to-action" @click="$emit('action')">
        <span class="call-to-action__label">
            <slot></slot>
        </span>
        <span class="call-to-action__value" v-if="value">{{ value }}</span>
    </div>
</template>

<script>
export default {
    name: 'CallToAction',
    props: {
        value: String,
    }
}
</script>

<style scoped lang="scss">
.call-to-action {
    height: 58px;
    max-width: 350px;
    background-color: var(--rose);
    border-radius: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    color: var(--white);
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;

    &__disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &__label {
        display: flex;
        align-items: center;
        gap: 1em;
    }
    &__value {
        margin-left: auto;
    }
    &__white {
        background-color: var(--white);
        color: var(--gray);
    }
    &__black {
        background-color: var(--black);
    }
}
</style>