import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CallToAction from '@/components/CallToAction.vue'
import SelectComponent from '@/components/Select.vue'
import ArrowComponent from '@/components/Arrow.vue'
import VideoComponent from '@/components/Video.vue'

createApp(App)
    .component('CallToAction', CallToAction)
    .component('SelectComponent', SelectComponent)
    .component('ArrowComponent', ArrowComponent)
    .component('VideoComponent', VideoComponent)
    .use(router)
    .mount('#app')

