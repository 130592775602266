<template>
    <a v-if="product" class="product" :href="product.url" target="_blank">
        <div class="product__illustration">
            <img :src="product.image" />
        </div>
        <h3 class="product__name">{{ product.name }}</h3>
        <div class="product__description">{{ product.description }}</div>
        <div class="product__price">{{ product.price }}</div>
    </a>
    <div v-else class="product product__skeleton" aria-hidden="true">
        <div class="product__illustration"></div>
        <h3 class="product__name">Name</h3>
        <div class="product__description">Description</div>
        <div class="product__price">Price</div>
    </div>
</template>

<script>
export default {
    name: 'ProductCard',
    props: [ 'product' ]
}
</script>

<style lang="scss" scoped>
.product {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__illustration {
        border-radius: 20px;
        overflow: hidden;
        background-color: var(--packshot-background);
        display: flex;
        aspect-ratio: 321 / 393;

        img {
            width: 100%;
            display: block;
            height: auto;
        }
    }

    &__name {
        font-family: var(--corps-font);
        font-size: 16px;
        line-height: 28px;
        color: var(--gray);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__description {
        font-family: var(--corps-font);
        font-size: 16px;
        line-height: 20.7px;
        height: 41.4px;
        color: var(--grey);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__price {
        font-family: var(--corps-font);
        font-size: 16px;
        font-weight: 700;
        line-height: 28px;
        color: var(--gray);
    }

    &__skeleton {
        cursor: wait;
        .product__name,
        .product__description,
        .product__price {
            background-color: var(--light-grey);
            border-radius: 20px;
            color: transparent;
        }
    }
    &__placeholder {
        opacity: 0;
        pointer-events: none;
    }
}
</style>
