<template>
    <div class="home">
        <HeaderComponent />
        <HeroComponent />
        <BirthComponent />
        <SliderComponent v-if="!isMobile" />
        <SliderMobileComponent v-else />
        <RevealComponent ref="reveal" />
        <BiancalanaComponent ref="biancalana" />
        <RangeComponent />
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import HeaderComponent from '@/components/Header.vue'
import HeroComponent from './Hero/index.vue'
import BirthComponent from './Birth.vue'
import SliderComponent from './Slider/index.vue'
import SliderMobileComponent from './Slider/Mobile.vue'
import RevealComponent from './Reveal.vue'
import BiancalanaComponent from './Biancalana.vue'
import RangeComponent from './Range/index.vue'

import Device from '@/pure/Device'
import gsap from '@gsap/shockingly'
import ScrollTrigger from '@gsap/shockingly/ScrollTrigger'

export default {
    name: 'HomeView',
    components: {
        HeaderComponent,
        HeroComponent,
        BirthComponent,
        SliderComponent,
        SliderMobileComponent,
        RevealComponent,
        BiancalanaComponent,
        RangeComponent
    },
    setup() {
        const reveal = ref(null);
        const biancalana = ref(null);
        let sts = [];
        const handleResize = () => {
            sts.forEach(_ => {
                _.refresh(true);
            })
        }
        onMounted(() => {
            sts.push(ScrollTrigger.create({
                pin: reveal.value.$el,
                start: "top top",
                end: () => {
                    return `+=${reveal.value.$el.offsetHeight}`
                },
                pinSpacing: false,
                scrub: true,
                invalidateOnRefresh: true
            }));

            const $reveal = reveal.value.$el;
            const breakPoint = 1024;
            ScrollTrigger.create({
                trigger: $reveal,
                start: "top center",
                onEnter() {
                    if (reveal.value) reveal.value.pristine = false
                }
            });

            Device.matchMedia.add({
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
            },
                (context) => {
                    let { isDesktop, isMobile } = context.conditions;
                    if (isDesktop) {
                        const animation1 = new gsap.timeline()
                            .fromTo($reveal.querySelector('.cover'), {
                                y: '47.8472222222vw',
                            }, {
                                y: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelector('.row-1'), {
                                x: '-139.3vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelectorAll('.row-2 .miss-dior:nth-child(n+3)'), {
                                x: '49.51vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelectorAll('.row-2 .miss-dior:nth-child(-n+2)'), {
                                x: '-51.8vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelector('.row-3'), {
                                x: '148.4vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                        ScrollTrigger.create({
                            trigger: $reveal,
                            start: "top center",
                            animation: animation1,
                        });
                    } else if (isMobile) {
                        const animation1 = new gsap.timeline()
                            .fromTo($reveal.querySelector('.cover'), {
                                y: '144.28vw'
                            }, {
                                y: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .from($reveal.querySelectorAll('.row:nth-child(odd)'), {
                                x: '-175vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelector('.row:nth-child(even)'), {
                                x: '244vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')
                            .fromTo($reveal.querySelector('.row-3'), {
                                x: '114vw',
                            }, {
                                x: '0vw',
                                ease: 'sine.out',
                                duration: 2
                            }, 'a')

                        ScrollTrigger.create({
                            trigger: $reveal,
                            start: "top center",
                            animation: animation1,
                        });
                    }

                    return () => { };
                })


            const animation2 = new gsap.timeline()
                .fromTo(biancalana.value.$el.querySelector('.illustration__image'), {
                    y: 200,
                }, {
                    y: -200,
                    ease: 'linear'
                }, 'a')
                .fromTo(biancalana.value.$el.querySelector('.illustration__video'), {
                    y: 300,
                }, {
                    y: -300,
                    ease: 'linear'
                }, 'a')
            sts.push(ScrollTrigger.create({
                trigger: biancalana.value.$el.querySelector(".view__fullscreen"),
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                animation: animation2,
                invalidateOnRefresh: true
            }));

            const animation3 = new gsap.timeline()
                .fromTo(biancalana.value.$el.querySelector('.typography'), {
                    y: 200,
                    opacity: 0,
                }, {
                    y: 0,
                    opacity: 1,
                    ease: 'linear',
                    duration: 1
                }, 'a')
            sts.push(ScrollTrigger.create({
                trigger: biancalana.value.$el.querySelector(".wrapper"),
                start: "top center",
                animation: animation3,
            }));

            ScrollTrigger.addEventListener('refresh', handleResize);
        })
        onUnmounted(() => {
            sts.forEach(_ => {
                if (_) _.kill();
            })
            ScrollTrigger.removeEventListener('refresh', handleResize);
        })
        return {
            isMobile: Device.isMobile,
            reveal,
            biancalana
        }
    }
}
</script>

<style lang="scss" scoped>
.reveal {
    z-index: 0;
}

.biancalana {
    position: relative;
    z-index: 1;
    margin-top: -1px;
}
</style>
