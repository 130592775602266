<template>
    <div class="slider view__fullscreen">
        <div class="slider__block block">
            <div class="slider__wrapper">
                <div>
                    <div class="pagination">
                        <Transition :css="false" @enter="yEnter" @leave="yLeave">
                            <span class="pagination__count" :key="idx">{{ slideCountLabel }}</span>
                        </Transition> / {{ slidesLength }}
                    </div>
                    <CopyComponent :slide="slide" :idx="idx" />
                </div>
            </div>
            <div class="slider__controls">
                <button class="activable" @click="previous">
                    <ArrowComponent />
                </button>
                <button class="activable" @click="next">
                    <ArrowComponent class="arrow__right" />
                </button>
            </div>
            <img class="slider__decoration slider__decoration-bottom" src="@/assets/images/slider/decorations/01.png" alt="decoration" aria-hidden="true" />
            <img class="slider__decoration slider__decoration-top" src="@/assets/images/slider/decorations/02.png" alt="decoration" aria-hidden="true" />
        </div>
        <div class="illustration">
            <Transition :css="false" @enter="imageEnter" @leave="imageLeave">
                <picture class="cover__picture" :key="idx">
                    <source :srcset="`${slide.media.default}, ${slide.media['2x']} 2x`" />
                    <img :src="slide.media.default" alt="Discovery of the garden" />
                </picture>
            </Transition>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue'

import { slides } from '@/locales'

import CopyComponent from './Copy.vue'

import { useAnimations } from '@/composables/useAnimations'

export default {
    name: 'SliderComponent',
    components: {
        CopyComponent
    },
    setup() {
        const idx = ref(0);

        const slide = computed(() => slides[idx.value])
        const slideCountLabel = computed(() => (1e15 + (idx.value + 1) + '').slice(-2))
        const slidesLength = computed(() => (1e15 + (slides.length) + '').slice(-2))

        const next = () => {
            idx.value = idx.value < slides.length - 1 ? idx.value + 1 : 0;
        }
        const previous = () => {
            idx.value = idx.value > 0 ? idx.value - 1 : slides.length - 1;
        }

        const handleAction = () => {
            window.open("https://www.dior.com/en_us/beauty/products/miss-dior-parfum-C099700897.html")
        }

        const { imageEnter, imageLeave, yEnter, yLeave } = useAnimations();

        return {
            slides,
            slide,
            idx,
            slideCountLabel,
            slidesLength,
            next,
            previous,
            imageEnter,
            imageLeave,
            yEnter,
            yLeave,
            handleAction
        }
    }
}
</script>

<style lang="scss" scoped>
.slider {
    gap: 16px;
    align-items: stretch;
    padding: 16px 10px;

    &__block {
        background-color: var(--white);
        border-radius: 20px;
        flex: 1;
        min-height: 100%;
        display: flex;
        position: relative;
    }

    &__wrapper {
        margin: auto;

        @include respond-to("xs") {
            max-width: 504px;
            width: 100%;
        }
    }

    &__controls {
        display: flex;
        gap: 5px;
        position: absolute;
        bottom: 55px;
        left: 0;
        width: 100%;
        max-width: 504px;
        margin: auto;
        right: 0;
        padding: 0 20px;
    }

    &__decoration {
        position: absolute;
        &-top {
            left: 5%;
            top: 40px;
            width: 146px;
        }
        &-bottom {
            right: 5%;
            bottom: 40px;
            width: 123px;
        }
    }
}

.pagination {
    font-size: 12px;
    margin-bottom: 18px;
    line-height: 24px;
    overflow: hidden;
    height: 24px;
    position: relative;
    padding: 0 16px;

    &__count {
        display: inline-flex;
    }
}

.illustration {
    border-radius: 10px;
    flex: 1;
    background-color: var(--white);
    min-height: 100%;
    overflow: hidden;
    position: relative;

    picture {
        display: block;
        position: absolute;
        inset: 0;
        will-change: transform;
        overflow: hidden;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        will-change: transform;
        display: block;
    }
}

.activable {
    padding: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 20px;
}
</style>
