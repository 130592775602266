export default class Pagination {
    page = 1;
    totalItems = 0;
    totalPages = 0;
    size = 0;
    constructor({ size }) {
        if (size) this.size = size;
    }

    nextPage() {
        this.page += 1;
        return this.page;
    }

    nextPageAvailable() {
        return this.page < this.totalPages;
    }

    reset() {
        this.page = 1;
    }
}
